import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import User from "../../models/User";
import { AuthService } from "../../services/AuthService";


export interface AuthState {
  currentUser: User | null;
}

const initialState: AuthState = {
  currentUser: null,
};

export const getCurrentUser = createAsyncThunk<User, string>(
  "auth/current",
  async (token) => {
    const res = await AuthService.getCurrentUser(token);
    return res?.data;
  }
);

export const updateUser = createAsyncThunk<User, Partial<User>>(
  "user/update",
  async (data:Partial<User>) => {
    const res = await AuthService.updateUser(data);
    return res?.data;
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async () => {
    const res = await AuthService.logout();
    return res;
  }
);


const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
      }).addCase(logout.fulfilled, (state, action) => {
        state.currentUser = null;
      })
  },
});

const  authReducer = authSlice.reducer;

export default authReducer;
