import React from 'react'
import "./../../styles/components/forms/LoginForm.css"
import { Form, Button, Alert, Spinner, InputGroup } from 'react-bootstrap';
import User from '../../models/User';
import { validatePhoneNumber } from '../../utils/utils';
import { FaWhatsapp } from 'react-icons/fa';

interface AuthFormProps {
  onLogin: (user: Pick<User, 'phone' | 'otp' | 'name'>) => Promise<any>;
  onSendOTP: (phone: string, email: string, name: string) => Promise<any>;
  loading: boolean;
  formError?: string | null;
  step: 0 | 1;
}


const AuthForm: React.FC<AuthFormProps> = ({ onLogin, loading, formError, step, onSendOTP }) => {
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [name, setName] = React.useState<string>('');
  const [otp, setOTP] = React.useState<string>('');
  const [error, setError] = React.useState<string | null>(null);

  const handleSendOTP = (e: React.MouseEvent) => {
    e.preventDefault()

    setError(null);

    // Perform validation
    if (!validatePhoneNumber(phoneNumber)) {
      setError('Please enter a valid phone number.');
      return;
    }

    // Clear any previous error messages
    setError(null);


    onSendOTP(phoneNumber, email, name);
  };

  const handleLogin = (e: React.MouseEvent) => {
    e.preventDefault()

    setError(null);

    // Perform validation
    if (!validatePhoneNumber(phoneNumber)) {
      setError('Please enter a valid phone number.');
      return;
    }

    // Clear any previous error messages
    setError(null);

    // Call the onLogin function
    const user: Pick<User, 'phone' | 'email' | 'otp' | 'name'> = {
      phone: phoneNumber,
      otp,
      name,
      email
    }

    onLogin(user);
  };

  React.useEffect(() => {
    if (formError) {
      setError(formError)
    }
  }, [formError])

  return (
    <Form className='authform border rounded p-3 col-11 col-md-7 col-lg-6 col-xl-5 shadow'>
      <div className='d-flex justify-content-center mb-3'>
        <h2 className='text-center'>Bienvenue sur le service support de EasyTranfert</h2>
      </div>
      {error && <Alert variant="danger">{error}</Alert>}
      {
        step === 0 ?
          <>
            {/*             <Form.Group className='mb-3' controlId="formBasicPhoneNumber">
              <Form.Label>Adresse mail</Form.Label>
              <Form.Control
                type="mail"
                placeholder="Votre adresse mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group> */}
            <Form.Group className='mb-3' controlId="formBasicPhoneNumber">
            <Form.Label>Numéro de téléphone Whatsapp</Form.Label>

              <InputGroup>
                <InputGroup.Text>
                  <FaWhatsapp style={{color: "#25D366"}}/>
                </InputGroup.Text>
                <Form.Control
                  type="tel"
                  placeholder="Votre numéro de téléphone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className='mb-3' controlId="formBasicPhoneNumber">
              <Form.Label>Votre nom</Form.Label>
              <Form.Control
                type="name"
                placeholder="Votre nom"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Button disabled={loading} className='w-100' variant="primary" type="submit" onClick={handleSendOTP}>
              {loading ?
                <span>Validation en cours <Spinner animation='grow' size='sm' /> </span>
                : <span>Valider</span>
              }
            </Button>
          </>
          :
          <>
            <Alert variant="info">Nous vous avons envoyé un code OTP par SMS, veuillez le saisir pour continuer svp.</Alert>
            <Form.Group className='mb-3' controlId="formBasicPhoneNumber">
              <Form.Control
                type="number"
                placeholder="00000"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
              />
            </Form.Group>
            <Button disabled={loading || otp.length !== 5} className='w-100' variant="primary" type="submit" onClick={handleLogin}>
              {loading ?
                <span>Validation en cours <Spinner animation='grow' size='sm' /> </span>
                : <span>Valider</span>
              }
            </Button>
          </>
      }
    </Form>
  );
};

export default AuthForm;

