import { Modal } from "react-bootstrap";
import { FaChartBar } from "react-icons/fa";
import { Chat } from "../../models/Chat";
import { STATUS } from "../../utils/constants";

interface StatModalOptions {
    chats: Chat[],
    onHide: () => void
}

const StatModal: React.FC<StatModalOptions> = ({ onHide, chats }) => {

    return <Modal style={{ backdropFilter: 'blur(2px)' }} show={true} centered onHide={onHide} size={'lg'}>
        <Modal.Header className="border-0" style={{backgroundColor: 'var(--bgColor)'}} closeButton>
            <Modal.Title>
                <div className='d-flex gap-1 align-items-center'>
                    <FaChartBar className='text-primary' style={{ fontSize: '1.2em' }} />
                    <span className="chat-message-username"> Statistiques du chat</span>
                </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: 'var(--bgColor)'}}>
            <div className="row">
                <div className='border modal-selection-item active cursor-pointer col rounded p-2 m-2 align-items-center justify-content-center d-flex'>
                    Total clients : {chats.length}
                </div>
                <div className='border modal-selection-item active cursor-pointer col rounded p-2 m-2 align-items-center justify-content-center d-flex'>
                    Total messages : {chats.reduce((prev, curr) => {
                        return prev + curr.messages.length
                    }, 0)}
                </div>
                {
                    STATUS.map(value => {
                        return <div className='border modal-selection-item active cursor-pointer col rounded p-2 m-2 align-items-center justify-content-center d-flex'>
                            clients ({value.text}) : {chats.filter((chat) => (chat.user.conversation_status === value.value)).length}
                        </div>
                    })
                }
            </div>
        </Modal.Body>
    </Modal>
}

export default StatModal;