import { HTMLAttributes, useEffect, useState } from 'react'
import '../../styles/components/commons/TopBar.css'
import User from '../../models/User'
import { generatePlaceholderImageURL, getInitials } from '../../utils/utils'
import { FaChartBar, FaMoon, FaSun } from 'react-icons/fa'
import { FEELING, STATUS } from '../../utils/constants'
import StatModal from './StatModal'
import { useSelector } from 'react-redux'
import { ChatState } from '../../redux/slices/chatSlice'


interface TopBarProps extends HTMLAttributes<HTMLDivElement> {
    user: User
    selectedUser: User | null
    onBack: () => void
}

export const TopBar = ({ user, selectedUser, onBack, ...props }: TopBarProps) => {

    const chatData = useSelector<unknown, ChatState>((state: any) => state.chats).chats;
    const userLogo = generatePlaceholderImageURL(user.name);
    const seletedUserLogo = generatePlaceholderImageURL(selectedUser?.name ?? '');
    const darkModeStorage = localStorage.getItem('mode');
    const [openChatModal, setOpenChatModal] = useState(false);
    const [darkMode, setDarkMode] = useState(darkModeStorage === 'true');


    const toggleDarkMode = () => {
        setDarkMode((prevMode) => !prevMode);
    };

    useEffect(() => {
        document.body.classList.toggle('dark', darkMode);
        localStorage.setItem('mode', darkMode ? 'true' : 'false')
    }, [darkMode]);

    return (
        <div {...props} className={`${props.className} d-flex justify-content-between container align-items-center`}>
            {selectedUser && <div className='d-flex d-flex align-items-center gap-2'>
                {/*  <FaLongArrowAltLeft color='white' className='ms-2 chat-message-username' onClick={() => onBack()} /> */}
                <div className={`ms-auto d-flex h-100 align-items-center me-3 text-light gap-2`}>
                    <div className='border initial-image text-white bg-secondary d-flex justify-content-center align-items-center'>
                        <span>{getInitials(selectedUser?.name ?? '')}</span>
                    </div>
                    <span className='chat-message-username'>{selectedUser.name}</span>
                </div>
            </div>}
            {darkMode ? (
                <FaSun
                    onClick={toggleDarkMode}
                    className="toggle-icon"
                />
            ) : (
                <FaMoon
                    onClick={toggleDarkMode}
                    className="toggle-icon"
                />
            )}
            {/* {!user.admin && <div className='d-flex justify-content-end w-100 align-items-center' style={{ height: '70px' }}>
                <img style={{ transform: 'scale(.75)' }} alt="Easy Transfert" src={require('./../../assets/images/easy-logo.png')} />
            </div>} */}
            {user.admin && <div className={`ms-auto d-none d-md-flex h-100 align-items-center me-3 text-light gap-2 chat-message-username`}>
                {user.admin && <div style={{ cursor: 'pointer' }} className='d-flex gap-1 align-items-center' onClick={() => setOpenChatModal(true)}>
                    <FaChartBar className='text-primary' style={{ fontSize: '1.2em' }} />
                    <span>Statistique</span>
                </div>}
                {user.admin && selectedUser && <div className='d-flex gap-4 border-end border-start px-2'>
                    <div className='d-flex gap-1 align-items-center'>
                        <div className='text-nowrap'>Status</div>
                        <div className='border-primary rounded p-1 m-1'>
                            {STATUS.find((v) => v.value === selectedUser.conversation_status)?.text ?? 'Aucun'}
                        </div>
                    </div>
                    <div className='d-flex gap-1 align-items-center'>
                        <div className='text-nowrap'>Sentiment</div>
                        <div className='border-primary rounded p-1 m-1'>
                            {FEELING.find((v) => v.value === selectedUser.conversation_feeling)?.text ?? 'Aucun'}
                        </div>
                    </div>
                </div>}
                <div className='d-flex gap-1 align-items-center'>
                    <img className='border' style={{ borderRadius: '100%' }} src={userLogo} alt="" />
                    <span className='text-nowrap'>{user.name}</span>
                </div>
            </div>}

            {openChatModal && <StatModal chats={chatData ?? []} onHide={() => setOpenChatModal(false)} />}
        </div>
    )
}
