import { HTMLAttributes, useState } from 'react';
import '../../styles/components/chat/ChatUserList.css';
import { FaCloud, FaSignOutAlt } from 'react-icons/fa';
import RecentChatList, { RecentChatListProps } from './RecentChatList';
import { Chat } from '../../models/Chat';
import SearchInput from '../commons/SearchInput ';
import { STATUS } from '../../utils/constants';

interface ChatUserListProps extends HTMLAttributes<HTMLDivElement> {
    chats: Chat[];
    onLogout: () => void;
}

export const ChatUserList = ({
    chats,
    onLogout,
    onSelectChat,
    ...props
}: ChatUserListProps & Pick<RecentChatListProps, 'onSelectChat'>) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedStatus, setSelectedStatus] = useState<null | string>(null);

    const handleSearch = (value: string) => {
        setSearchValue(value);
    };

    const filteredChats = chats.filter((chat) => {
        const userName = chat.user.name.toLowerCase();
        const messageContent = chat.messages
            .map((message) => message.content.toLowerCase())
            .join(' ');

        return (
            userName.includes(searchValue.toLowerCase()) ||
            messageContent.includes(searchValue.toLowerCase())
        ) && ((selectedStatus && selectedStatus.length > 0) ? chat.user.conversation_status === selectedStatus : true);
    });

    return (
        <div {...props}>
            <div className='d-flex align-content-center w-100 align-items-center' style={{ height: '70px' }}>
                {/* <img style={{ transform: 'translateX(-.8em)' }} src={LOGO_URL} alt="Easy Transfert" className='img-fluid mx-auto' /> */}
                <h3 className='ms-3'>EASYTRANSFERT</h3>
            </div>
            <div style={{ height: 'calc(105px)' }} className=" ChatUserList__container container d-flex flex-column">
                <SearchInput onChange={handleSearch} placeholder='Rechercher' />
                <select onChange={(e) => setSelectedStatus(e.target.value)} className='p-2 rounded m -1'>
                    {STATUS.map(({ value, text }, index) => (
                        <option
                            value={value}
                            key={index}
                            label={text}
                            id={`status-${index}`}
                        />
                    ))}
                </select>
            </div>
            <div style={{ height: 'calc(100% - 205px)', overflow: 'auto' }} className='RecentChatList__container'>
                {filteredChats.length !== 0 ?
                    <RecentChatList chats={filteredChats} onSelectChat={onSelectChat} />
                    :
                    <div
                        style={{ height: '100%', userSelect: 'none', color: '#8f8f8f20' }}
                        className='d-flex flex-column gap-2 align-items-center justify-content-center text-gray text-uppercase fs-5'>
                        <FaCloud size={40} color="#8f8f8f20" />
                        <span>aucune discussion</span>
                    </div>
                }
            </div>
            <div onClick={onLogout} style={{ height: '30px', color: '#777d83bf !important', cursor: 'pointer' }}
                className="ChatUserList__Logout d-flex justify-content-center align-items-center gap-2">
                <span>Déconnexion</span>
                <FaSignOutAlt size={20} />
            </div>
        </div>
    );
};
