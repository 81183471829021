import React, { useState, useEffect, useRef } from 'react';
import { InputGroup } from 'react-bootstrap';
import { FaArrowCircleRight, FaPlus, FaPlusCircle, FaRegSmile, FaTelegram } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import User from '../../models/User';
import { AuthState } from '../../redux/slices/authSlice';

export type TMediaType = 'image' | 'video' | 'audio';
export type TMessage = {
    content: string;
    media: {
        type: TMediaType;
        ext: string;
        file: File;
    } | null;
};

export interface ChatInputProps {
    onSendMessage: (message: TMessage) => void;
    onOpenModal: () => void;
    disabled?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, onOpenModal, disabled }) => {
    const authData = useSelector<any, AuthState>((state: any) => state.auth).currentUser as User;

    const [message, setMessage] = useState<TMessage>({
        content: '',
        media: null,
    });
    const inputRef = useRef<HTMLInputElement>(null);

    const handleSendMessage = () => {
        if (!disabled && message.content.replaceAll(' ', '') !== '' || message.media !== null) {
            onSendMessage(message);
            setMessage({
                content: '',
                media: null,
            });
        }
    };

    const handleSelectFile = () => {
        if (inputRef.current && !disabled) inputRef.current.click();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const canSendMessage = message.content.replaceAll(' ', '').length > 0 || message.media !== null;

    const handleOpenModal = () => {
        onOpenModal();
    };

    useEffect(() => {
        const inputElement = inputRef.current;
        const handleFileChange = (ev: Event) => {
            const files = (ev.target as HTMLInputElement).files;
            if (files && files.length > 0) {
                const file = files[0];
                const fileType = file.type.startsWith('image') ? 'image' :
                    file.type.startsWith('video') ? 'video' :
                        file.type.startsWith('audio') ? 'audio' : 'image';
                const fileExtension = file.name.split('.').at(-1) ?? "";

                setMessage((prev: TMessage) => ({
                    ...prev,
                    media: {
                        type: fileType,
                        file: file,
                        ext: fileExtension,
                    },
                }));

                onSendMessage({
                    ...message,
                    media: {
                        type: fileType,
                        file: file,
                        ext: fileExtension,
                    },
                });
            }
        };

        if (inputElement) {
            inputElement.addEventListener('change', handleFileChange);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('change', handleFileChange);
            }
        };
    }, [message, onSendMessage]);

    return (
        <div className="ChatInput d-flex justify-content-between align-items-center rounded w-100">
            <InputGroup className="d-flex position-relative justify-content-between border-top align-items-center">
                <div className="ChatInput__SelectFile d-flex gap-2 px-2">
                    <FaPlusCircle title="Ajouter un fichier à la conversation" onClick={handleSelectFile} className="ChatInput__SendButton__icon" size="1.7em" />
                    {authData.admin && <FaRegSmile onClick={handleOpenModal} title="Ajouter un sentiment à la conversation" className="ChatInput__SendButton__icon" size="1.7em" />}
                    <input ref={inputRef} hidden type="file" multiple={false} accept="image/*, video/*" />
                </div>
                <textarea
                    autoFocus
                    placeholder={disabled?"Appuyez sur un bouton...":"Entrer votre message..."}
                    value={message.content}
                    onChange={(e) => {!disabled && setMessage((prev: TMessage) => ({ ...prev, content: e.target.value }))}}
                    className="form-control rounded"
                    rows={1}
                    style={{ resize: 'none', fontSize:'16px' }}
                />
                {<div className="ChatInput__SendButton px-2">
                    <FaArrowCircleRight className={`ChatInput__SendButton__icon ${canSendMessage ? 'ChatInput__SendButton__icon--active' : 'ChatInput__SendButton__icon--active'}`} size="1.9em" onClick={handleSendMessage} />
                </div>}
            </InputGroup>
        </div>
    );
};

export default ChatInput;
