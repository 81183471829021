const LOGO_URL = 'https://easytransfert.ci/images/logo/logo6.png';
const FEELING = [
    { value: 'retarget', text: 'Retarget', emoji: '😊' },
    { value: 'satisfied', text: 'Satisfait', emoji: '😌' },
    { value: 'angry', text: 'En colère', emoji: '😡' },
    { value: 'indifferent', text: 'Indifférent', emoji: '😐' },
/*     { value: 'excited', text: 'Excité', emoji: '😁' },
    { value: 'confused', text: 'Confus', emoji: '😕' },
    { value: 'grateful', text: 'Reconnaissant', emoji: '🙏' },
    { value: 'inspired', text: 'Inspiré', emoji: '🌟' },
    { value: 'hopeful', text: 'Plein d\'espoir', emoji: '🌈' },
    { value: 'nostalgic', text: 'Nostalgique', emoji: '📜' },
    { value: 'relaxed', text: 'Détendu', emoji: '😎' },
    { value: 'surprised', text: 'Surpris', emoji: '😮' },
    { value: 'anxious', text: 'Anxieux', emoji: '😰' },
    { value: 'overwhelmed', text: 'Dépassé', emoji: '😩' },
    { value: 'content', text: 'Content', emoji: '😊' },
    { value: 'joyful', text: 'Joyeux', emoji: '😄' }, */
  ];
  

const STATUS = [
    { value: '', text: 'Aucun status' },
]

const STATUS_MAPPER: any = {
    status_bot: {
        title: 'status_bot',
        color: 'pink',
    },
    not_read: {
        title: 'Non lu',
        color: 'red',
    },
    opened: {
        title: 'Ouvert',
        color: 'var(--primaryColor)',
    },
    ongoing: {
        title: 'En cours de traitement',
        color: 'yellow',
    },
    completed_success: {
        title: 'Terminé succès',
        color: 'green',
    },
    completed_failure: {
        title: 'Terminé échec',
        color: '#E0F8F7',
    },
    completed_reboot: {
        title: 'Redémarrage',
        color: 'violet',
    },
    more_info: {
        title: 'Plus d\'info',
        color: '#FF9933',
    },
}

Object.keys(STATUS_MAPPER).forEach((key) => {
    STATUS.push({
        value: key,
        text: STATUS_MAPPER[key].title
    })
})

export {
    LOGO_URL,
    FEELING,
    STATUS,
    STATUS_MAPPER
}