import React from 'react';
import { FaArrowDown } from 'react-icons/fa';

interface ScrollDownButtonProps {
    onClick: () => void;
    visible: boolean;
}

const ScrollDownButton: React.FC<ScrollDownButtonProps> = ({ onClick, visible }) => {
    return (
        <button
            onClick={onClick}
            style={{
                position: 'fixed',
                bottom: '100px',
                right: '15px',
                backgroundColor: '#007bff90',
                borderRadius: '50%',
                border: 'none',
                padding: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                zIndex: 1000,
                transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
                opacity: visible ? 1 : 0,
                transform: visible ? 'translateY(0)' : 'translateY(15px)',
                pointerEvents: visible ? 'auto' : 'none',
            }}
        >
            <FaArrowDown color="white" size="24px" />
        </button>
    );
};

export default ScrollDownButton;
