import React from 'react'
import '../styles/pages/LoginPage.css'
import { AuthService } from '../services/AuthService'
import User from '../models/User'
import AuthForm from '../components/forms/AuthForm'


export const AuthPage = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [formError, setFormError] = React.useState<string | null>(null);
    const [authFormStep, setAuthFormStep] = React.useState<0 | 1>(0);

    const handleLogin = (user: Partial<User>) => {
        setLoading(true)
        setFormError(null)
        return AuthService.login(user).then((res) => {
            const authToken = res?.data?.token;
            if (authToken) {
                localStorage.setItem('authToken', authToken);
                window.location.reload();
            } else {
                console.error('Something went wrong')
            }
        }).catch((err) => {
            if (err?.code === "ERR_NETWORK") {
                setFormError("Erreur de connexion réseau, veuillez vérifier votre connexion internet.")
            } else {
                setFormError(err?.response?.data?.error ?? "Une erreur s'est produite veuillez réessayer.");
            }
        }).finally(() => setLoading(false))
    }

    const handleOneTimeAuthLogin = (phone: string, email:string, name:string) => {
        setLoading(true)
        setFormError(null)
        return AuthService.oneTimeAuthLogin({phone, email, name}).then((res) => {
            const authToken = res?.data?.token;
            if (authToken) {
                localStorage.setItem('authToken', authToken);
                window.location.reload();
            } else {
                console.error('Something went wrong')
            }
        }).catch((err) => {
            if (err?.code === "ERR_NETWORK") {
                setFormError("Erreur de connexion réseau, veuillez vérifier votre connexion internet.")
            } else {
                setFormError(err?.response?.data?.error ?? "Une erreur s'est produite veuillez réessayer.");
            }
        }).finally(() => setLoading(false))
    }

    const handleSendOTP = (phone: string, email:string, name:string) => {
        setLoading(true)
        setFormError(null)
        return AuthService.sendOTP(phone, email, name).then((res) => {
            setAuthFormStep(1);
        }).catch((err) => {
            if (err?.code === "ERR_NETWORK") {
                setFormError("Erreur de connexion réseau, veuillez vérifier votre connexion internet.")
            } else {
                setFormError(err?.response?.data?.error ?? "Une erreur s'est produite veuillez réessayer.");
            }
        }).finally(() => setLoading(false))
    }


    return (
        <div className='LoginPage h-100 w-100 d-flex justify-content-center align-items-center'>
            <AuthForm formError={formError} loading={loading} onLogin={handleLogin} onSendOTP={handleOneTimeAuthLogin} step={authFormStep} />
        </div>
    )

}
