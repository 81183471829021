import axios from "axios";
import User from "../models/User";

const getCurrentUser = (token: string) => {
    return axios
        .get(`auth/user`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        })
        .then((data) => {

            return data;
        });
};

const updateUser = (user: Partial<User>) => {
    return axios({
        url: `auth/user`,
        method: "POST",
        data: {
            id: user.id,
            ...user,
        },
        headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    }).then((data) => {
        return data;
    });
};


const login = (user: Partial<User>) => {
    return axios({
        url: `auth/login`,
        method: "POST",
        data: {
            ...user,
        },
    }).then((data) => {
        return data;
    });
};

const oneTimeAuthLogin = (user: Partial<User>) => {
    return axios({
        url: `auth/onetime-auth-token`,
        method: "POST",
        data: {
            ...user,
        },
    }).then((data) => {
        return data;
    });
};

const sendOTP = (phone: string, email: string, name: string) => {
    return axios
        .get(`auth/user/send-otp?phone=${phone}&name=${name}&email=${email}`, {
        })
        .then((data) => {
            return data;
        });
};

const logout = () => {
    return Promise.resolve(true)
};




export const AuthService = {
    getCurrentUser,
    sendOTP,
    logout,
    login,
    updateUser,
    oneTimeAuthLogin
};
