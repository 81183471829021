import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Chat } from "../../models/Chat";
import { ChatService } from "../../services/ChatService";
import { logout, updateUser } from "./authSlice";
import { Message } from "../../models/Message";
import User from "../../models/User";

export interface ChatState {
    chats: Chat[] | null
}

const initialState: ChatState = {
    chats: null,
};

export const getChats = createAsyncThunk<Chat[]>(
    "chats/get",
    async () => {
        const res = await ChatService.getChats();
        return res.data;
    }
);

export const resetChats = createAsyncThunk<Chat[]>(
    "chats/reset",
    async () => {
        return [];
    }
);


export const addMessage = createAsyncThunk<Message, Message>(
    "chats/add-message",
    async (data: Message) => {
        const res = data;
        return res;
    }
);
export const updateUserConversationStatus = createAsyncThunk<{userId:number, status:string}, {userId:number, status:string}>(
    "chats/user/status/update",
    async (data: {userId:number, status:string}) => {
        const res = data;
        return res;
    }
);


const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getChats.fulfilled, (state, action) => {
                state.chats = action.payload;
            }).addCase(resetChats.fulfilled, (state, action) => {
                state.chats = action.payload;
            }).addCase(updateUser.fulfilled, (state, action) => {
                if (state.chats) {
                    const chats = state.chats.map((chat) => {
                        if (chat.user.id === action.payload.id) {
                            return {
                                ...chat,
                                user: {
                                    ...chat.user,
                                    conversation_feeling: action.payload.conversation_feeling,
                                    conversation_status: action.payload.conversation_status,
                                }
                            }
                        }
                        return chat
                    });
                    state.chats = chats as Chat[];
                }
            }).addCase(updateUserConversationStatus.fulfilled, (state, action) => {
                if (state.chats) {
                    const chats = state.chats.map((chat) => {
                        if (chat.user.id === action.payload.userId) {
                            return {
                                ...chat,
                                user: {
                                    ...chat.user,
                                    conversation_status: action.payload.status,
                                }
                            }
                        }
                        return chat
                    });
                    state.chats = chats as Chat[];
                }
            }).addCase(logout.fulfilled, (state, action) => {
                state.chats = null;
            }).addCase(addMessage.fulfilled, (state, action) => {
                const message = action.payload as Message;

                if (state.chats && state.chats.length > 0) {
                    const chats = (state.chats as Chat[])
                        .map((chat) => {
                            if (chat.user.id === (message.recipient as User).id || chat.user.id === (message.sender as User).id) {
                                const messages = chat.messages;
                                if (!messages.find(m => (m as any).id === (message as any).id)) {
                                    return { ...chat, messages: [...chat.messages, message] }
                                }
                            }
                            return chat
                        });

                    const chatFound = chats.find((chat) => [(message.recipient as User).id, (message.sender as User).id].includes(chat.user.id))

                    if (!chatFound) {
                        // It's a new chat with first message
                        const sender = message.sender as User
                        const recipient = message.recipient as User
                        const user = (!sender.admin && !sender.bot) ? sender : recipient;

                        const chat: Chat = {
                            user,
                            messages: [message]
                        }

                        state.chats = [chat, ...state.chats]
                    } else {

                        state.chats = chats;
                    }

                } else {
                    const sender = message.sender as User
                    const recipient = message.recipient as User
                    const user = (!sender.admin && !sender.bot) ? sender : recipient;

                    const chat: Chat = {
                        user,
                        messages: [message]
                    }

                    state.chats = [chat]
                }
            })
    },
});

const chatReducer = chatSlice.reducer;

export default chatReducer;
