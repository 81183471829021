import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import authReducer from "./slices/authSlice";
import chatReducer from "./slices/chatSlice";


export const store  = configureStore({
    reducer:{
        auth: authReducer,
        chats: chatReducer
    },
    devTools: process.env.NODE_ENV === "development",
});

setupListeners(store.dispatch);