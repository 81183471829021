import React from 'react'
import '../styles/pages/ChatPage.css'
import { useDispatch, useSelector } from 'react-redux'
import { AuthState, logout, updateUser } from '../redux/slices/authSlice'
import User from '../models/User'
import { TopBar } from '../components/commons/TopBar'
import { ChatUserList } from '../components/chat/ChatUserList'
import { ChatContent } from '../components/chat/ChatContent'
import { ChatState, addMessage, getChats, resetChats, updateUserConversationStatus } from '../redux/slices/chatSlice'
import { AnyAction } from '@reduxjs/toolkit'
import { SpinnerScalingDotRing } from '../components/spinners/SpinnerScalingDotRing'
import { Chat } from '../models/Chat'
import { FaCloud } from 'react-icons/fa';
import { Socket, io } from 'socket.io-client'
import { TMessage } from '../components/chat/ChatInput'
import { Message } from '../models/Message'

export const ChatPage = () => {

    const authData = useSelector<unknown, AuthState>((state: any) => state.auth).currentUser as User;
    const chatData = useSelector<unknown, ChatState>((state: any) => state.chats).chats;
    const dispatch = useDispatch();
    const socketInstance = React.useRef<Socket<any, any> | null>(null);


    const [selectedChat, setSelectedChat] = React.useState<Chat | null>(null);

    const handleSendMessage = (message: TMessage) => {
        if (selectedChat) {
            let recipientId = !authData.admin ? 1 : selectedChat.user.id;
            if (!authData.admin && chatData && chatData.length !== 0) {
                const chatMessages = [...chatData[chatData.length - 1].messages];
                const lastAdminSender = chatMessages.reverse().find((value: Message) => ((value.sender as User).id !== authData.id && !(value.sender as User).bot));
                if (lastAdminSender) {
                    recipientId = (lastAdminSender.sender as User).id;
                }
            }

            const payload = {
                message,
                senderId: authData.id,
                recipientId
            }
// A comment
            if (payload.message.media?.file) {
                const reader = new FileReader();
                reader.onload = (event: ProgressEvent<FileReader>) => {
                    if (payload.message.media?.file) {
                        payload.message.media.file = (event.target as any).result
                        socketInstance.current?.emit('CREATE_MESSAGE', payload)
                    }
                }
                // Another comment
                try{
                    reader.readAsArrayBuffer(payload.message.media.file)
                }catch(err){
                    console.log(err);
                }
            } else {
                socketInstance.current?.emit('CREATE_MESSAGE', payload)
            }


        }
    }

    const handleUpdateConversation = async (data: object) => {
        return dispatch(updateUser(data) as unknown as AnyAction).unwrap();
    }

    const handleSelectChat = (chat: Chat) => {
        setSelectedChat(chat);
    }

    const handleLogout = () => {
        dispatch(logout() as unknown as AnyAction).unwrap()
            .then(() => {
                localStorage.removeItem('authToken')
                window.location.reload()
            }).catch((err: any) => console.log(err))
    }

    const handleGoBack = () => {
        if (!authData.admin) {
            handleLogout()
        } else {
            setSelectedChat(null)
        }
    }

    const handleInteractiveResponse = (questionId: string, responseId: string) => {
        if (socketInstance.current) {
            console.log({questionId, responseId});
            socketInstance.current.emit('REPLY_BOT', { question_id: questionId, reply_id: responseId });
        }
    }

    React.useEffect(() => {
        // Listen for events from the server
        const socket = io((process.env.REACT_APP_API_BASE_URL as string).replace('api/', ''), {
            auth: {
                token: localStorage.getItem("authToken")
            }
        });
        socketInstance.current = socket;

        socket.on('connect', () => {
            console.log('Connected to the server');
        });

        socket.on('NEW_MESSAGE', (message) => {
            dispatch(addMessage(message) as unknown as AnyAction)
        });

        socket.on('UPDATE_CONVERSATION_STATUS', (message) => {
            dispatch(updateUserConversationStatus(message) as unknown as AnyAction)
        });

        socket.on('RESET_CHAT_AFTER_BOT_REBOOT', (message) => {
            dispatch(resetChats() as unknown as AnyAction)
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from the server');
        });

        // Clean up the socket connection when the component unmounts
        return () => {
            socket.disconnect();
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (!chatData) {
            dispatch(getChats() as unknown as AnyAction)
        } else {
            if (selectedChat) {
                const updatedSelectedChat = chatData.slice()
                    .find((chat) => chat.user.id === selectedChat.user.id);
                if (updatedSelectedChat) {
                    setSelectedChat(updatedSelectedChat);
                }
            } else if (!authData.admin) {
                handleSelectChat(chatData[0])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatData, dispatch, selectedChat])

    return (
        <>
            {(chatData && authData) ?
                <div
                    style={{ "--chatList-XS-Width": `${selectedChat ? '0vw' : '100vw'}` } as React.CSSProperties}
                    className={`ChatPage d-flex align-items-center ${authData.admin?'':'user-chat'}`}>
                    {authData.admin && <ChatUserList
                        onLogout={handleLogout}
                        onSelectChat={handleSelectChat}
                        chats={chatData}
                        className='ChatPage__ChatUserList'
                    />}
                    <div
                        className="ChatPage__content d-flex flex-column h-100 w-100">
                        <TopBar
                            className='ChatPage__content__topbar'
                            style={{ height: '70px' }}
                            user={authData}
                            selectedUser={selectedChat?.user ?? null}
                            onBack={() => handleGoBack()}
                        />

                        {selectedChat ? <ChatContent
                            chat={selectedChat}
                            onSendMessage={handleSendMessage}
                            onUpdateConversation={handleUpdateConversation}
                            onInteractiveResponse={handleInteractiveResponse}
                            style={{ height: 'calc(100vh - 70px)' }}
                            className='ChatPage__ChatContent w-100'
                        /> : <div style={{ height: 'calc(100vh - 70px)', userSelect: 'none', color: '#8f8f8f20' }} className='d-flex flex-column gap-2 align-items-center justify-content-center text-gray text-uppercase fs-4'>
                            <FaCloud size={60} color="#8f8f8f20" />
                            <span>sélectionnez une discussion</span>
                        </div>}
                    </div>
                </div> : <SpinnerScalingDotRing />}
        </>
    )
}
