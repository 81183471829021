import React from 'react';
import './../../styles/components/chat/CustomMessageBox.css';
import { TMediaType } from './ChatInput';

interface CustomMessageBoxProps {
    content: string;
    mediaType: TMediaType;
    mediaData: string;
    containerStyle?: React.CSSProperties;
    timestamp: string;
    position: 'left' | 'right'
}

export const CustomMessageBox = (props: CustomMessageBoxProps) => {
    const renderMediaContent = () => {
        switch (props.mediaType) {
            case 'image':
                return <img className='media-content' src={props.mediaData} alt='' />;
            case 'video':
                //<video className='media-content' controls playsInline src={props.mediaData} />;
                return <video  loop={true}  preload="metadata" controls={true} playsInline={true}  style={{ maxWidth: '100%', maxHeight: "200px" }}><source  src={props.mediaData+"#t=0.005"} type="video/mp4"></source></video> ;
            case 'audio':
                return <audio className='media-content' controls src={props.mediaData} />;
            default:
                return null;
        }
    };

    return (
        <div className={`CustomMessageBox d-flex w-100 ${props.position}`}>
            <div className='CustomMessageBox_container d-flex justify-content-end' style={props.containerStyle ?? {}}>
                <div className='CustomMessageBox__content rounded'>
                    <div className='d-flex flex-column gap-1'>
                        {renderMediaContent()}
                        <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
