import moment from "moment";

export function generatePlaceholderImageURL(name: string, size: number = 30): string {
  // Extract initials from the name
  let initials;
  if (name.includes(' ')) {
    initials = name
      .split(' ')
      .map((word) => word.charAt(0))
      .join('')
      .toUpperCase();
  } else {
    initials = name.substring(0, 2).toUpperCase();
  }

  // Customize the placeholder image URL
  const imageURL = `https://via.placeholder.com/${size}x${size}.png/3C91EC/FFFFFF?text=${initials}`;

  return imageURL;
}
export function getInitials(name: string): string {
  // Extract initials from the name
  let initials;
  if (name.includes(' ')) {
    initials = name
      .split(' ')
      .map((word) => word.charAt(0))
      .join('')
      .toUpperCase();
  } else {
    initials = name.substring(0, 2).toUpperCase();
  }

  return initials;
}


export function whatsappMarkdownToHtml(text: string, plainTextUrl = true) {
  // Convert lists
  text = text.replace(/^\s*\*\s+(.*)$/gm, '<b><ul><li>$1</li></ul></b>'); // Unordered list
  text = text.replace(/^\s*1\.\s+(.*)$/gm, '<ol><li>$1</li></ol>'); // Ordered list

  // Convert new lines to HTML <br> elements
  text = text.replace(/\n/g, '<br>');

  // Convert bold text
  text = text.replace(/(\*\*|__)(.*?)\1/g, '<strong>$2</strong>');

  // Convert italic text
  text = text.replace(/(\*|_)(.*?)\1/g, '<em>$2</em>');

  // Convert code
  text = text.replace(/`([^`]+)`/g, '<code>$1</code>');

  // Convert strikethrough
  text = text.replace(/(~)(.*?)\1/g, '<del>$2</del>');

  // Convert code block (multiline)
  text = text.replace(/```(.*?)```/gs, '<pre>$1</pre>');

  // Convert code block (single line)
  text = text.replace(/`([^`]+)`/g, '<code>$1</code>');

  if (plainTextUrl) {
    // Convert real links
    text = text.replace(/(https?:\/\/[^\s]+)/g, '<a target="_blank" href="$1">$1</a>');
  }

  return text;
}

export const validatePhoneNumber = (phoneNumber: string) => {
  //const phoneRegex = /^225\d{10}$/;
  //return phoneRegex.test(phoneNumber);
  return true;
};
export const renderTimestamp = (timestamp: string) => {
  return moment(timestamp).format('H:mm');
};
