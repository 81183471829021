import React from 'react';
import User from '../../models/User';
import '../../styles/components/chat/RecentChatItem.css'
import { generatePlaceholderImageURL, renderTimestamp, whatsappMarkdownToHtml } from '../../utils/utils';
import { FEELING, STATUS_MAPPER } from '../../utils/constants';

interface RecentChatItemProps {
    user: User;
    lastMessage: string;
    date: string | null;
    selected: boolean;
    responseCelerity: number | null
}

const RecentChatItem: React.FC<RecentChatItemProps> = ({ user, lastMessage, date, selected, responseCelerity }) => {
    const userProfile = generatePlaceholderImageURL(user.name);


    const statusInfo = STATUS_MAPPER[user.conversation_status ?? 'status_bot'];
    const feelingInfo = FEELING.find((v) => v.value === user.conversation_feeling)

    return (
        <div className={`RecentChatItem d-flex gap-3 bg-white p-2 py-3 ${selected ? 'RecentChatItem--selected' : ''}`}
            style={{ border: `1px solid ${statusInfo.color}` }}
        >
            <div className='d-flex align-items-center '>
                <img src={userProfile} alt={`${user.name}`} className="profile-image mx-auto border" />
            </div>
            <div className='d-flex flex-column w-100 gap-2'>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex'><h5 className=''>{`${user.name}`}</h5></div>
                    <div className='chat-date timestamp small text-end user-select-none'><span>{date?renderTimestamp(date):''}</span></div>
                </div>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: `${whatsappMarkdownToHtml(lastMessage).slice(0, 40)}...` }} className="last-message"></div>
                </div>
                <div className='d-flex justify-content-end gap-2 chat-date timestamp'>
                    {<span>VR: {`${responseCelerity ? responseCelerity + 'min' : '_'}`}</span>}
                    |
                    {feelingInfo && <span>{feelingInfo.text} {feelingInfo.emoji}</span>}
                    |
                    {statusInfo && <span>{statusInfo.title}</span>}
                </div>
            </div>
        </div>


    );
};

export default RecentChatItem;
