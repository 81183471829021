import React, { ChangeEvent } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs'; // Importez l'icône de recherche depuis react-icons
import '../../styles/components/commons/SearchInput.css'

interface SearchInputProps {
    placeholder: string;
    onChange: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, onChange }) => {
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };

    return (
        <InputGroup className="SearchInput mb-3">
            <InputGroup.Text id="search-addon">
                <BsSearch />
            </InputGroup.Text>
            <FormControl
                placeholder={placeholder}
                aria-label="Search"
                aria-describedby="search-addon"
                onChange={handleInputChange}
            />
        </InputGroup>
    );
};

export default SearchInput;
