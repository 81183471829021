import React, { HTMLAttributes, useState, useEffect, useRef, useCallback } from 'react';
import ChatInput, { ChatInputProps, TMessage } from './ChatInput';
import '../../styles/components/chat/ChatContent.css';
import { Chat } from '../../models/Chat';
import "react-chat-elements/dist/main.css";
import { getInitials, renderTimestamp, whatsappMarkdownToHtml } from '../../utils/utils';
import { CustomMessageBox } from './CustomMessageBox';
import { v4 as uuidv4 } from 'uuid';
import { Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import User from '../../models/User';
import { AuthState } from '../../redux/slices/authSlice';
import ChatMediaContainer from './ChatMediaContainer';
import { FEELING, STATUS } from '../../utils/constants';
import moment from 'moment' ;
import ScrollDownButton from '../commons/ScrollDownButton';

interface ChatContentProps extends HTMLAttributes<HTMLDivElement> {
    chat: Chat;
    onUpdateConversation: (data: object) => Promise<void>;
    onInteractiveResponse: (questionId: string, responseId: string) => void;
}

const ChatContent = ({ onSendMessage, onUpdateConversation, chat, onInteractiveResponse, ...props }: ChatContentProps & Pick<ChatInputProps, 'onSendMessage'>) => {
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const messagesEndRef=useRef<HTMLDivElement>(null);
    const authData = useSelector<any, AuthState>((state: any) => state.auth).currentUser as User;

    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [message, setMessage] = useState<TMessage>({ content: '', media: null });
    const [modalState, setModalState] = useState<null | 'feeling' | 'status'>(null);
    const [modalLoading, setModalLoading] = useState(false);
    const fileReader = new FileReader();
    const [selectedFeeling, setSelectedFeeling] = useState<string | null>(chat.user.conversation_feeling);
    const [selectedStatus, setSelectedStatus] = useState<string | null>(chat.user.conversation_status);
    const lastMessage = [...chat.messages].reverse().find((_, k) => k === 0);

    const handleSendMessage = (value: TMessage) => {
        if (fileUrl) {
            handleSendMessageDirectly(value.content);
        } else if (value.media) {
            setMessage(value);
        } else {
            onSendMessage(value);
        }
    };

    const handleSaveModal = async () => {
        setModalLoading(true);
        try {
            await onUpdateConversation({
                id: chat.user.id,
                conversation_feeling: selectedFeeling,
                conversation_status: selectedStatus,
            });
            handleCloseModal();
        } catch (error) {
            console.error(error);
        } finally {
            setModalLoading(false);
        }
    };

    const handleSendMessageDirectly = (content: string) => {
        onSendMessage({ ...message, content });
        handleCloseMediaContainer();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onSendMessage(message);
        }
    };

    const handleCloseMediaContainer = () => {
        setFileUrl(null);
        setMessage({ content: '', media: null });
    };

    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
        setModalState(null);
        setModalLoading(false);
    };

    const [buttonLoading, setButtonLoading] = useState(false);

    const handleInteractiveResponse = (responseID: string, questionID: string) => {
        onInteractiveResponse(questionID, responseID);
    };

    const scrollDown = () => {
        if (messagesEndRef.current) {
            //chatContainerRef.current.scrollTop= chatContainerRef.current.scrollHeight;
            messagesEndRef.current.scrollIntoView(true);

            //setButtonLoading(false);
            //setShowScrollDownButton(false); 
        }
    };

    const handleScroll = () => {
        if (chatContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
            setShowScrollDownButton(scrollHeight - scrollTop > clientHeight + 50);
        }
    };

    useEffect(() => {
        scrollDown();
    }, [chat.messages]);

    useEffect(() => {
        if (message.media) {
            fileReader.onload = function (ev: ProgressEvent<FileReader>) {
                setFileUrl(ev.target?.result as string);
            };
            fileReader.readAsDataURL(message.media.file);
        }
    }, [message]);

    const renderButtons = (message: any, i: number, messages: any) => (
        !authData.admin && i === messages.length - 1 && (
            <div className='d-flex gap-2 w-100 flex-wrap'>
                {message.buttons && message.buttons.map((button: any) => (
                    <button
                        onClick={() => handleInteractiveResponse(button.id, message.botQuestion ?? "")}
                        disabled={buttonLoading}
                        className='interactive'
                        key={button.id}
                    >
                        {buttonLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                        {button.title}
                    </button>
                ))}
            </div>
        )
    );

    const renderMessage = (message: any, i: number, messages: any) => {
        const isRight = message.sender && typeof message.sender !== 'number' &&
            ((message.sender.id === authData.id) || (message.sender.bot && authData.admin));

        const position = isRight ? 'right' : 'left';
        const userName = message.sender.pseudo;

        const userNameStyles = { fontSize: '.8em', color: '#bbb' };
        const showInitialImage = i === messages.length - 1 || messages[i + 1]?.sender?.id !== message.sender.id;
        const currentTimestamp = renderTimestamp(message.createdAt);
        const nextTimestamp = i < messages.length - 1 && messages[i + 1].sender?.id === message.sender.id ? renderTimestamp(messages[i + 1].createdAt) : null;
        const showTimestamp = !nextTimestamp || nextTimestamp !== currentTimestamp;

        return (
            <div key={uuidv4()}>
                {isRight ? (
                    <div style={{ marginRight: '20px', ...userNameStyles }} className='chat-message-username text-muted user-select-none d-flex justify-content-end'>
                        {renderButtons(message, i, messages)}
                    </div>
                ) : (
                    <div style={{ marginLeft: '20px', ...userNameStyles }} className='chat-message-username user-select-none d-flex flex-column gap-2 justify-content-start'>
                        {renderButtons(message, i, messages)}
                    </div>
                )}
                <CustomMessageBox
                    position={position as any}
                    key={uuidv4()}
                    containerStyle={{ maxWidth: '95%' }}
                    content={whatsappMarkdownToHtml(message.content, false)}
                    mediaType={message.mediaType}
                    mediaData={message.mediaData}
                    timestamp={message.createdAt}
                />
                {isRight ? (
                    <div style={{ marginRight: '20px', ...userNameStyles }} className='chat-message-username right text-muted user-select-none d-flex justify-content-end'>
                        {showInitialImage && (
                            <div className="initial-image-container">
                                <div className='initial-image text-white bg-secondary d-flex justify-content-center align-items-center'>
                                    <span>{getInitials(userName)}</span>
                                </div>
                            </div>
                        )}
                        {showTimestamp && (
                            <div style={{ fontSize: '.8em' }} className="timestamp text-end user-select-none">
                                {currentTimestamp}
                            </div>
                        )}
                    </div>
                ) : (
                    <div style={{ marginLeft: '20px', ...userNameStyles }} className='chat-message-username left user-select-none d-flex flex-column gap-2 justify-content-start'>
                        {showInitialImage && (
                            <div className="initial-image-container">
                                <div className='initial-image text-white bg-secondary d-flex justify-content-center align-items-center'>
                                    <span>{getInitials(userName)}</span>
                                </div>
                            </div>
                        )}
                        {showTimestamp && (
                            <div style={{ fontSize: '.8em' }} className="timestamp text-start user-select-none">
                                {currentTimestamp}
                            </div>
                        )}
                    </div>
                )}

                
            </div>
        );
    };


    const groupMessagesByDate = (messages: any) => {
        const grouped: { [key: string]: any[] } = {};
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'day').startOf('day');

        messages.forEach((message: any) => {
            let date: string;
            const messageDate = moment(message.createdAt);

            if (messageDate.isSame(today, 'day')) {
                date = 'Aujoud\'hui';
            } else if (messageDate.isSame(yesterday, 'day')) {
                date = 'Hier';
            } else {
                date = messageDate.format('MMM, DD Y');
            }

            if (!grouped[date]) {
                grouped[date] = [];
            }
            grouped[date].push(message);
        });
        return grouped;
    };

    const groupedMessages = groupMessagesByDate(chat.messages);
    const chatInputActive = (authData.admin??false) ? true:(lastMessage && (((lastMessage.buttons && lastMessage.buttons?.length === 0)) || !lastMessage || !lastMessage.buttons));
    const [showScrollDownButton, setShowScrollDownButton] = useState(false);
    


    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            //chatContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (chatContainer) {
                //chatContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <div {...props} className={`${props.className} d-flex flex-column`}>
            <div className="ChatContent__Container flex-grow-1" ref={chatContainerRef}>
                {Object.keys(groupedMessages).map(date => (
                    <div key={date}>
                        <div className="ChatContent__Date">{date}</div>
                        {groupedMessages[date].map((message, i, messages) => renderMessage(message, i, messages))}
                        <div id="phincode" ref={messagesEndRef}/>
                    </div>
                ))}
                
            </div>
            <div className={`ChatContent__ChatInput d-flex flex-column shadow-top`}>
                {fileUrl && <div className='me-auto px-2'>
                    <ChatMediaContainer
                        fileUrl={fileUrl}
                        chatContainerWidth={'100%'}
                        message={message as any}
                        handleCloseMediaContainer={handleCloseMediaContainer}
                    />
                </div>}
                <ChatInput disabled={!chatInputActive} onSendMessage={handleSendMessage} onOpenModal={handleOpenModal} />
            </div>
            <ScrollDownButton visible={showScrollDownButton} onClick={scrollDown} />
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {modalState === null ? "Ajouter un sentiment ou un status" : modalState === 'feeling' ? "Ajouter un sentiment" : "Ajouter un status"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalState === null ? (
                        <div className='d-flex row gap-2 align-items-center justify-content-center'>
                            <div onClick={() => setModalState('feeling')} className='border modal-selection-item cursor-pointer col-5 rounded p-2 m-2 align-items-center justify-content-center d-flex'>
                                AJOUTER UN SENTIMENT
                            </div>
                            <div onClick={() => setModalState('status')} className='border modal-selection-item cursor-pointer col-5 rounded p-2 m-2 align-items-center justify-content-center d-flex'>
                                AJOUTER UN STATUS
                            </div>
                        </div>
                    ) : modalState === 'feeling' ? (
                        <>
                            <p>Quel est votre sentiment par rapport à cette conversation ?</p>
                            <Form>
                                {FEELING.map(({ value, text, emoji }, index) => (
                                    <Form.Check
                                        value={value}
                                        key={index}
                                        type="radio"
                                        label={<span>{text} {emoji}</span>}
                                        name="feeling"
                                        checked={value === selectedFeeling}
                                        id={`feeling-${index}`}
                                        onChange={() => setSelectedFeeling(value)}
                                    />
                                ))}
                            </Form>
                        </>
                    ) : (
                        <>
                            <p>Quel est votre status par rapport à cette conversation ?</p>
                            <Form>
                                {STATUS.map(({ value, text }, index) => (
                                    <Form.Check
                                        value={value}
                                        key={index}
                                        type="radio"
                                        label={text}
                                        name="status"
                                        checked={value === selectedStatus}
                                        id={`status-${index}`}
                                        onChange={() => setSelectedStatus(value)}
                                    />
                                ))}
                            </Form>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={modalState === null || modalLoading}
                        style={{ backgroundColor: 'var(--primaryColor)' }}
                        variant="primary"
                        onClick={handleSaveModal}>
                        Enregistrer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export { ChatContent };

