import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, getCurrentUser } from '../redux/slices/authSlice';
import { LoginPage } from './LoginPage';
import { AnyAction } from '@reduxjs/toolkit';
import { ChatPage } from './ChatPage';
import { SpinnerScalingDotRing } from '../components/spinners/SpinnerScalingDotRing';
import '../styles/pages/HomePage.css'
import { AuthPage } from './AuthPage';


type ActivePage = 'login' | 'chat';

export const HomePage = () => {

    const authData = useSelector<unknown, AuthState>((state: any) => state.auth).currentUser;
    const authToken = localStorage.getItem('authToken');
    const [activePage, setActivePage] = React.useState<ActivePage | null>(null)

    const dispatch = useDispatch();

    const PAGES: Record<ActivePage, React.JSX.Element> = {
        login: <AuthPage />,
        chat: <ChatPage />
    }

    const renderPage = (page: ActivePage) => PAGES[page]

    React.useEffect(() => {
        if (!authData) {
            if (authToken) {
                dispatch(getCurrentUser(authToken) as unknown as AnyAction)
                    .unwrap().then((response: any) => {
                        /* console.log(response); */
                        setActivePage('chat');
                    }).catch((error: any) => {
                        console.log(error)
                        setActivePage('login');
                    });
            } else {
                setActivePage('login');
            }
        } else {
            setActivePage('chat');
        }
    }, [authData, authToken, dispatch])

    return (
        <div className='HomePage d-flex align-items-center justify-content-center'>
            {(activePage === null) ?
                <SpinnerScalingDotRing />
                :
                renderPage(activePage)
            }
        </div>
    )

}
