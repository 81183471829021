import React from 'react'
import "./../../styles/components/forms/LoginForm.css"
import { Form, Button, InputGroup, Alert, Spinner } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { UserLogin } from '../../models/User';
import { validatePhoneNumber } from '../../utils/utils';
import { LOGO_URL } from '../../utils/constants';

interface LoginFormProps {
  onLogin: (user: UserLogin) => Promise<any>;
  loading: boolean;
  formError?: string | null
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin, loading, formError }) => {
  const [phoneNumber, setPhoneNumber] = React.useState<string>('237695904403');
  const [password, setPassword] = React.useState<string>('password');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const handleLogin = (e: React.MouseEvent) => {
    e.preventDefault()

    setError(null);

    // Perform validation
    if (!validatePhoneNumber(phoneNumber)) {
      setError('Please enter a valid phone number.');
      return;
    }

    if (password.length < 6) {
      setError('Password must be at least 6 characters long.');
      return;
    }

    // Clear any previous error messages
    setError(null);

    // Call the onLogin function
    const user: UserLogin = {
      phone: phoneNumber,
      password
    }

    onLogin(user);
  };

  React.useEffect(() => {
    if (formError) {
      setError(formError)
    }
  }, [formError])

  return (
    <Form className='authform border rounded p-3 col-11 col-md-7 col-lg-6 col-xl-5 shadow'>
      <div className='d-flex justify-content-center mb-3'>
        <h2 className='text-center'>Bienvenue sur le service support de EasyTranfert</h2>
      </div>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form.Group className='mb-3' controlId="formBasicPhoneNumber">
        <Form.Label>Numéro de téléphone</Form.Label>
        <Form.Control
          type="tel"
          placeholder="Votre numéro de téléphone"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}  
        />
        <Form.Text className="text-muted">
          Numéro de téléphone
        </Form.Text>
      </Form.Group>

      <Form.Group className='mb-4' controlId="formBasicPassword">
        <Form.Label>Mot de passe</Form.Label>
        <InputGroup>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            placeholder="Votre mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputGroup.Text
            onClick={() => setShowPassword(!showPassword)}
            style={{ cursor: 'pointer' }}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Button disabled={loading} className='w-100' variant="primary" type="submit" onClick={handleLogin}>
        {loading ?
          <span>Connexion en cours <Spinner animation='grow' size='sm' /> </span>
          : <span>Se connecter</span>
        }
      </Button>
    </Form>
  );
};

export default LoginForm;

