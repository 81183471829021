import React from 'react';

interface ChatMediaContainerProps {
    fileUrl: string;
    chatContainerWidth?: string;
    message: {
        media?: {
            type: string;
        };
        content: string;
    };
    handleCloseMediaContainer: () => void;
}

const ChatMediaContainer: React.FC<ChatMediaContainerProps> = ({
    fileUrl,
    chatContainerWidth = '100%',
    message,
    handleCloseMediaContainer,
}) => {
    return (
        <div className='ChatInput__mediaContainer' style={{ width: chatContainerWidth }}>
            <div onClick={handleCloseMediaContainer} className="ChatInput__mediaContainer__closeBtn"><span>X</span></div>
            <div className="">
                <div className="ChatInput__mediaContainer__preview">
                    {message.media?.type === 'image' && (
                        <img src={fileUrl} alt="Preview" className='img-fluid' />
                    )}
                    {message.media?.type === 'video' && (
                        <video  loop={true} autoPlay={true} preload="metadata" controls={true} playsInline={true}  style={{ maxWidth: '100%', maxHeight: "200px" }}>
                            <source  src={fileUrl+"#t=0.005"} type="video/mp4"></source>
                        </video>    
                    )}
                    {message.media?.type === 'audio' && (
                        <audio controls src={fileUrl} style={{ width: '100%' }} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatMediaContainer;
