import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Error404Page } from './pages/Error404Page';
import axios from 'axios';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { HomePage } from './pages/HomePage';
import { LoginPage } from './pages/LoginPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  { path: '/', element: <HomePage /> },
  { path: '/admin', element: <LoginPage /> },
  { path: '*', element: <Error404Page /> }
]);

// default axios configs
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

