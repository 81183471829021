import axios from "axios";


const getChats = () => {
  return axios
    .get(`auth/chats`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      return data;
    });
};


export const ChatService = {
  getChats
}