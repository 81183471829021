import React from "react";
import { Chat } from "../../models/Chat";
import { ListGroup } from "react-bootstrap";
import RecentChatItem from "./RecentChatItem";
import { v4 as uuidv4 } from 'uuid';
import { Message } from "../../models/Message";
import User from "../../models/User";
import moment from "moment";

export interface RecentChatListProps {
    chats: Chat[];
    onSelectChat: (chat: Chat) => void;
}


const RecentChatList: React.FC<RecentChatListProps> = ({ chats, onSelectChat }) => {

    const [selectedChat, setSelectedChat] = React.useState<Chat | null>(null)

    const responseCelerity = (messages: Message[]) => {
        const lastBotMessageIndex = messages.findIndex((message, index)=>{
            const sender = message.sender as User;
            /* if(sender.bot) console.log(message, index); */
            return sender.bot;
        });

        if(lastBotMessageIndex === -1) return null;
        
        const lastAdminMessageIndex = messages.findIndex((message, index)=>{
            const sender = message.sender as User;
            // if(index>lastBotMessageIndex &&  sender.admin && !sender.bot) console.log(message, index);
            return index<lastBotMessageIndex &&  sender.admin && !sender.bot;
        });

        if(lastAdminMessageIndex === -1) return null;

        return Math.round(moment(messages[lastAdminMessageIndex].createdAt)
        .diff(messages[lastBotMessageIndex].createdAt, 'milliseconds')/(1000.0 * 60))

    }

    // Sort chats by the most recent last message
    const sortedChats = chats.slice().sort((a, b) => {
        const lastMessageA = a.messages[a.messages.length - 1];
        const lastMessageB = b.messages[b.messages.length - 1];

        // Check if last message exists
        if (!lastMessageA || !lastMessageA.createdAt) return 1;
        if (!lastMessageB || !lastMessageB.createdAt) return -1;

        return moment(lastMessageB.createdAt).diff(moment(lastMessageA.createdAt));
    });


    return (
        <ListGroup>
            {sortedChats.map((chat, index) => (
                <ListGroup.Item
                    key={uuidv4()}
                    onClick={() => { setSelectedChat(chat); onSelectChat(chat) }}
                    className="bg-transparent border-0"
                >
                    <RecentChatItem
                        user={chat.user}
                        lastMessage={([...chat.messages].reverse())[0]?.content ?? ''}
                        date={([...chat.messages].reverse())[0]?.createdAt ?? null}
                        selected={chat.user.id === selectedChat?.user.id}
                        responseCelerity={responseCelerity([...chat.messages].reverse())}
                    />
                    {(index !== chats.length - 1) &&
                        <div className="border-bottom mt-2 opacity-25"></div>}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
}

export default React.memo(RecentChatList);